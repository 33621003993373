<template>
  <div id="ai">
    <div v-if="_g_IsUserLogged" id="nav-tools" class="information-mobile">
      <div class="counter-stocks">
        <span>{{this.number_stocks}}</span>
      </div>
      <div id="container-scroll" style="display: flex; width: 85%; overflow-x: scroll">
        <div v-for="(ticker, index) in stocks" :key="index" class="tickers-name" @click="_changeTicker(ticker, index)" >
          <span :id="'stock'+index" v-if="_g_User.is_pre_eureker_user && ticker.nom == '---'" class="mdc-list-item__text typo-body-2" @click="askPreEurekersTickerInfo(ticker.ticker)">
            <span class="material-icons-outlined">visibility</span>
          </span>
          <span v-else class="ticker-box" :id="'stock'+index"> {{ticker.ticker}}</span>
        </div>
      </div>
    </div>

    <div v-else id="nav-tools">
    
    </div>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";

export default {
  mixins: [APICaller, GetStocks],
  name: "Ai",
  components: {
  },
  data: () => ({
    index: 0,
    stocks: [],
    number_stocks: 0,
    last_ticker_selected: '',
  }),
  watch:{ 
      _g_stocks: {      
        handler: function() {
          this.stocks = this._g_stocks;
          this.number_stocks = this.stocks.length;
          this._startComponent();
        },
        deep: true
      },   
  },
  mounted() {
    var waiting_for_stocks = setInterval(() => {
      if(this.stocks.length != 0){
        this._startComponent();
        clearInterval(waiting_for_stocks);
      }
    }, 200);
  },
  created() {
    
  },
  computed: {
    ...mapGetters(["_g_SelectedTicker", "_g_IsUserLogged", "_g_User"]),
    ...mapGetters({_g_stocks:"_g_Stocks"}),
  },
  methods: {
    askPreEurekersTickerInfo(id) {
      let params = new URLSearchParams();
      params.append('ticker_id', id);
      params.append('uid', this._g_User.uid);

      let success= response => {
        this.getStocks();
        this.getPreEurekersRemainingTickers();
      };

      let url = '/usuarios/add-requested-ticker';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('252', 'Error agregando valor.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    getPreEurekersRemainingTickers() {
      let success = response => {
        this.$vueOnToast.pop('success', 'Te quedan ' + response.data + ' visualizaciones disponibles');
        //TODO actualizar el objeto _g_User pedir info del back
      };
      
      let url = '/usuarios/get-remaining-ticker-requests';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('056', 'Error procesando la solicitud.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _startComponent: function(){
      let index = 0;
      let flag = false;
      this.stocks = this._g_stocks;
      this.stocks.forEach(element =>{
        if(element.ticker == this._g_SelectedTicker.ticker){
          flag = true;          
          return true;
        }
        if(!flag){
          index++;
        }
      })
      this.index = index;
      if(this._g_SelectedTicker != this.stocks[this.last_ticker_selected] && this.last_ticker_selected != ''){
        const stock_html_element = document.getElementById("stock" + this.last_ticker_selected);
        const stock_html_element_found = stock_html_element != null;
        if(stock_html_element_found) {
          stock_html_element.style.color = "black";
        } 
      }
      if(this.stocks.length != 0){
        const stock_html_element = document.getElementById("stock" + index);
        const stock_html_element_found = stock_html_element != null;
        if(stock_html_element_found) {
          stock_html_element.style.color = "#00ADD9";
        } 
        this.last_ticker_selected = this.index;
      }
      
    },
    _changeTicker: function(ticker, value){
      document.getElementById("stock" + value).style.color = "#00ADD9";
      document.getElementById("stock" + this.index).style.color = "black";
      this.index = value;
      this.last_ticker_selected = value;

      this.setSelectedActiveTicker(ticker)
    },
    ...mapMutations(['setSelectedTicker']),
    setSelectedActiveTicker(ticker) {
        this.setSelectedTicker(ticker);
    },

    _nextTicker: function(){
      let index = 0;
      let flag = false;
      this.stocks.forEach(element =>{
        if(element.ticker == this._g_SelectedTicker.ticker){
          flag = true;
          return true;
        }
        if(!flag){
          index++;
        }
      })
      if(index < this.stocks.length){
        let ticker = this.stocks[index + 1]
        this._changeTicker(ticker, index + 1)
        document.getElementById("container-scroll").scrollLeft += 60; 
      }
    }, 

    _prevTicker: function(){
      let index = 0;
      let flag = false;
      this.stocks.forEach(element =>{
        if(element.ticker == this._g_SelectedTicker.ticker){
          flag = true;
          return true;
        }
        if(!flag){
          index++;
        }
      })
      let ticker = this.stocks[index - 1]
      this._changeTicker(ticker, index - 1)
      document.getElementById("container-scroll").scrollLeft -= 60; 
    }
  }
};
</script>

<style lang="scss" scoped>
.information-mobile{
  display: flex;
}

.counter-stocks{
  min-width: 95px;
  height: 45px;
  left: 0px;
  top: 121px;
  border-top: 1px solid grey;
  background: var(--color-primary-primary);
  color: white;
  font-size: 25px;
  text-align:center;
}

.tickers-name{
  box-sizing: border-box;
  height: 36px;
  left: 95px;
  top: 121px;
  min-width: 60px;

/* Tema Claro/03. Background/Background */

  background: var(--color-custom-ticker-name-mobile);
/* Tema Claro/03. Background/Outline */

  border: 1px solid rgba(15, 15, 26, 0.2);
  text-align:center;
}

.ticker-box{
  height: 20px;
  left: 24.87%;
  right: 56.92%;
  top: calc(50% - 20px/2 - 282px);
  padding: 6px;

  /* Body 2 */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  /* Tema Claro/01. Accent/400-Accent */

  color: var(--color-custom-ticker-name-mobile-color);

}
</style>
